import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Image from '../../components/ParallaxImageHandler';
import Seo from '../../components/Seo';
import AnimatedBlob from '../../components/AnimatedBlob';
import AnimatedPhone from '../../components/AnimatedPhone';
import Banner from '../../components/Banner/index.jsx';
import { Container, Row, Col } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import FeatureCard from '../../components/FeatureCard';
import WistiaPopoverVideo from '../../components/WistiaPopoverVideo';
import { Waypoint } from 'react-waypoint';
import ShinyButton from '../../components/ShinyButton';
import Loader from '../../components/Loader';
import ResourceCard from '../../components/ResourceCard';

import './style.scss';

const SuperOps = ({ data }) => {
    const [animateHeader, setAnimateHeader] = useState(false);
    const [animatePhone, setAnimatePhone] = useState(false);
    const [blogPosts] = useState(data.allWordpressPost.edges);
    const [filteredBlogPosts, setFilteredBlogPosts] = useState(blogPosts);

    var searchValue = 'msp';
    useEffect(() => {
        let posts = blogPosts;
        if (searchValue) {
            posts = posts.filter(post => {
                const postTitle = post.node.title.toLowerCase();
                return postTitle.includes('msp');
            });
            searchValue = null;
        }

        document.querySelector('.stats-view-box').classList.add('animate');
        document.querySelector('.mobile-stats-view').classList.add('animate');
        setFilteredBlogPosts(posts);
    }, [searchValue]);

    return (
        <ParallaxProvider>
            <Layout>
                <Seo title="SuperOps" />
                <div className="superops-page">
                    <Container
                        className={`white-swish-top container-1 ${
                            {
                                /*animateHeader ? 'animate' : ''*/
                            }
                        }`}
                    >
                        {/*<Waypoint
                            onEnter={() => {
                                setAnimateHeader(true);
                            }}
                        />*/}
                        <h1>Say Hello to our friend</h1>
                        <div className="logo-div">
                            <img
                                className="superops-logo"
                                src={require('../../images/superops/superops-logo.png')}
                                alt="superops-logo"
                            />
                        </div>
                        <div className="flex justify-center hero-btns margin-top-50">
                            <WistiaPopoverVideo id={'x4xf1cr1tx'}>
                                <button className="btn blue margin-left-25">
                                    <span className="btn__mask"></span>
                                    <span className="btn__text arrow-btn">
                                        Watch Video
                                        <img
                                            src={require('../../images/home/right-arrow.png')}
                                            alt="right-arrow"
                                        />
                                    </span>
                                </button>
                            </WistiaPopoverVideo>
                        </div>
                    </Container>
                    <Container className="container-2">
                        <div className="mobile-stats-view"></div>
                        <div className="stats-view-box"></div>
                    </Container>
                    <Container className="container-3 pink-gradient-background">
                        <Row>
                            <Col className="box-container">
                                <h3 className="purple-text centered-text">
                                    Built for today's MSPs
                                </h3>
                                <p className="left-align">
                                    Everything that an MSP needs—ticketing,
                                    asset management, client management, project
                                    management and IT Documentation are brought
                                    together into a single platform,
                                    SuperOps.ai. In addition, when you sign up
                                    for SuperOps.ai you get a free GlassHive
                                    license for a complete sales and marketing
                                    experience.
                                </p>
                                <p className="left-align">
                                    No more jumping windows and struggling with
                                    multiple tools, our platform has all you
                                    need in one.
                                </p>
                                <p className="left-align">
                                    With the powerful, but fast software
                                    SuperOps.ai brings together PSA
                                    (Professional Services Automation) and RMM
                                    (Remote Monitoring & Management) so you can
                                    manage tasks, teams, clients, assets,
                                    finance, time, resources, and everything in
                                    between. AI and automation streamlines your
                                    tasks while learning your processes to make
                                    your workflow more productive and efficient.
                                </p>
                                <p className="left-align">
                                    Their platform also plays nice with the
                                    tools you already use. SuperOps.ai is packed
                                    with the right amount of features to delight
                                    you, your team, and your clients. Why not
                                    try it for yourself?
                                </p>
                            </Col>
                        </Row>
                        <Row className="struggle-row">
                            <Col className="centered-text max-width-900">
                                <h3 className="white-text">
                                    One platform for all your IT needs
                                </h3>
                                <p className="white-text wrapLineSib">
                                    Superops.ai is a unified platform, packed
                                    with tools, and features that every MSP
                                    needs:
                                </p>
                                <ul>
                                    <li className="white-text li">
                                        PSA allows you to work on tickets, tasks
                                        and projects in tandem
                                    </li>
                                    <li className="white-text li">
                                        Project Management tool make it easy to
                                        create, track, collaborate, and identify
                                        bottlenecks in your projects
                                    </li>
                                    <li className="white-text li">
                                        RMM lets you stay on top of your
                                        clients’ assets at all times
                                    </li>
                                    <li className="white-text li">
                                        IT Documentation stores, searches, and
                                        sorts your documents efficiently
                                    </li>
                                    <li className="white-text li">
                                        Intelligent Alerting helps you avoid
                                        alerts that consume time and proactively
                                        fixes the issue
                                    </li>
                                </ul>
                                <h1 className="white-text how-it-works-h1">
                                    How it works
                                </h1>
                                <p className="white-text wrapLineSib max-width-645 ">
                                    We’ve improved current processes and decided
                                    to give MSPs a breath of fresh air from
                                    existing legacy software with:
                                </p>
                            </Col>
                        </Row>
                        <Row className="feature-card-row">
                            {/*<Col>
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.first-card')
                                            .classList.add('animate');
                                        document
                                            .querySelector('.second-card')
                                            .classList.add('animate');
                                        document
                                            .querySelector('.third-card')
                                            .classList.add('animate');
                                    }}
                                />
                                </Col>*/}

                            <Col>
                                <FeatureCard
                                    src={require('../../images/superops/cloud.png')}
                                    body="A fully-unified platform of MSP 
                            tools, born in the cloud."
                                    class="abs first-card reach"
                                />
                            </Col>
                            <Col>
                                <FeatureCard
                                    src={require('../../images/superops/rainbow-wheel.png')}
                                    body="Snappy, modern, and intuitive UI 
                            to make everyday operations 
                            simple and efficient."
                                    class="abs second-card reach"
                                />
                            </Col>
                            <Col>
                                <FeatureCard
                                    src={require('../../images/superops/bullseye.png')}
                                    body="Automation and AI at the core 
                            of our platform."
                                    class="abs third-card reach"
                                />
                            </Col>
                        </Row>
                        <img
                            className="pink-shadow"
                            src={require('../../svgs/home/ping-shadow.svg')}
                            alt="pink shadow"
                        />
                    </Container>
                    <Container className="container-4 white-swish-mid transparent-background">
                        <Row>
                            <Col className="one-half">
                                <img
                                    className="partner-img"
                                    src={require('../../images/superops/partner-img.png')}
                                    alt="partnership"
                                />
                            </Col>
                            <Col className="one-half ">
                                <h3 className="purple-text centered-text margin-auto left-align max-width-435">
                                    Why you should choose us
                                </h3>
                                <p className="black-text left-align max-width-500">
                                    Let us help you solve the everyday
                                    challenges your MSP faces, to streamline
                                    your workflow so that you can focus on
                                    growth. Along with your SuperOps trial, your
                                    free GlassHive license will help you improve
                                    your sales and marketing efforts for better
                                    results.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="container-5 purple-gradient-background">
                        <Row className="flex">
                            <Col className="one-half-30 margin-top-negative">
                                <h3 className=" max-width-500 margin-auto left-align">
                                    All your favorite tools, right here
                                </h3>
                                <p className="white-text left-align max-width-500">
                                    Manage it all with SuperOps.ai. Our platform
                                    provides native integrations with apps MSPs
                                    use every day so you can deliver the best
                                    managed services like you always do.
                                </p>
                                <p className="white-text left-align max-width-500">
                                    All your favorite tools are together now. We
                                    know what you need, and everything is just a
                                    click away. As you explore our platform,
                                    discover how else we can improve your
                                    productivity and sales.
                                </p>
                            </Col>
                            {/*<Col className="one-half mobile-half">
                                <img
                                    className="mobile-img"
                                    src={require('../../images/superops/Reference.png')}
                                    alt="mobile"
                                />
                                <img
                                    className="circles-img"
                                    src={require('../../images/superops/Group 5809.png')}
                                    alt="circles"
                                />
                            </Col>*/}
                            <Waypoint
                                onEnter={() => {
                                    setAnimatePhone(true);
                                }}
                            />
                            <Col className="animated-phone-column max-width-500">
                                <AnimatedPhone animate={animatePhone} />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                                {/* <a href={'/'} className={'card one-half ebook'}>
                                    <div className="card-inner">
                                        <div className="top-img">
                                            <img
                                                src="https://honeycrmbuild.wpengine.com/wp-content/uploads/2022/01/Best-ways-to-build-a-personal-followup.png"
                                                alt="feat img thumbnail"
                                            />
                                        </div>
                                        <div className="card-text">
                                            <h3>
                                                What's in your MSP tool box?
                                            </h3>
                                            <div className="excerpt">
                                                <p>
                                                    So many services, so many
                                                    tools—where do you start and
                                                    how do you choose what to
                                                    add to your tool stack? We
                                                    address these and many other
                                                    questions in this extensive
                                                    guide on MSP (managed
                                                    service provider) tools.
                                                </p>
                                            </div>
                                            <div class="button">Read Book</div>
                                        </div>
                                    </div>
                                </a> */}
                                {/* {filteredBlogPosts.map((post, index) => {
                                    return (
                                        <React.Fragment
                                            key={'newsletter' + post.node.id}
                                        >
                                            <ResourceCard
                                                colLength={'full'}
                                                post={post}
                                                btn={true}
                                            />
                                        </React.Fragment>
                                    );
                                })} */}
                            </Col>
                            <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                                <form
                                    id="superops-form"
                                    className="pink-form"
                                    method="POST"
                                    name="Superops Form"
                                    action="/superops/#thanks"
                                    data-netlify="true"
                                    data-netlify-honeypot="bot-field"
                                    data-category="SuperOps"
                                    encType="multipart/form-data"
                                    onSubmit={e => {
                                        e.preventDefault();
                                        const submitButton = document.getElementById(
                                            'submit-btn'
                                        );
                                        const loader = document.querySelector(
                                            '.loader'
                                        );
                                        const superOpsForm = document.getElementById(
                                            'superops-form'
                                        );

                                        loader.style.display = 'block';
                                        submitButton.style.display = 'none';

                                        fetch(
                                            superOpsForm.getAttribute('action'),
                                            {
                                                method: 'POST',
                                                body: new FormData(
                                                    document.getElementById(
                                                        'superops-form'
                                                    )
                                                ),
                                            }
                                        ).then(response => {
                                            if (response.status === 200) {
                                                document.querySelector(
                                                    '#superops-form'
                                                ).style.display = 'none';
                                                document.querySelector(
                                                    '.contact-thank-you'
                                                ).style.display = 'block';
                                            } else {
                                                loader.style.display = 'none';
                                                document.getElementById(
                                                    'error-msg'
                                                ).style.display = 'block';
                                                submitButton.style.display =
                                                    'block';
                                            }
                                        });
                                    }}
                                >
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="superops-form"
                                    />
                                    <input
                                        type="hidden"
                                        name="bot-field"
                                        id="bot"
                                    />
                                    <h3 className="white-text form-title">
                                        Get started for free!
                                    </h3>
                                    <div className="what-do-we-call-you">
                                        <div className="field name-field">
                                            <label htmlFor="firstName" hidden>
                                                What’s your name?
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder="Name"
                                                required
                                            ></input>
                                        </div>
                                        <div className="field email-field">
                                            <label hidden htmlFor="email">
                                                What’s your email address?
                                            </label>
                                            <input
                                                id="email"
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                required
                                            ></input>
                                        </div>
                                        <div className="field">
                                            <label
                                                className="second-label"
                                                hidden
                                                htmlFor="company"
                                            >
                                                What company are you with?
                                            </label>
                                            <input
                                                id="company"
                                                type="text"
                                                name="company"
                                                placeholder="Company"
                                            />
                                        </div>
                                    </div>

                                    <div className="field comment-field">
                                        <label
                                            hidden
                                            className="textarea-label"
                                            htmlFor="message"
                                        >
                                            What question can we answer for you?
                                        </label>
                                        <textarea
                                            id="message"
                                            type="text"
                                            name="message"
                                            placeholder="Comments"
                                        ></textarea>
                                    </div>
                                    <div className="hide-me field">
                                        <input
                                            data-form-type="Consultation"
                                            className="formcat"
                                            name="formType"
                                        />
                                    </div>
                                    <div className="submit-btn">
                                        <Loader />
                                        <p id="error-msg">
                                            Looks like there was a problem
                                            submitting your form. Please ensure
                                            ALL form fields are filled out and
                                            try again.
                                        </p>
                                        <button
                                            id="submit-btn"
                                            type="submit"
                                            className="pink-button"
                                        >
                                            Check Us Out
                                        </button>
                                    </div>
                                </form>
                                <div
                                    className="contact-thank-you reach"
                                    id="thanks"
                                >
                                    <h5>
                                        Thank you for contacting us. We'll be in
                                        touch shortly!
                                    </h5>
                                </div>
                            </Col>
                        </Row>
                        <AnimatedBlob
                            cls="canvas-1"
                            fill="rgba(255,137,157, .25)"
                        />
                        <AnimatedBlob
                            cls="canvas-2"
                            fill="rgba(255,137,157, .25)"
                        />
                    </Container>
                </div>
            </Layout>
        </ParallaxProvider>
    );
};

export const query = graphql`
    {
        allWordpressPost {
            edges {
                node {
                    id
                    title
                    date
                    excerpt
                    content
                    slug
                    featured_media {
                        localFile {
                            url
                        }
                    }
                    categories {
                        name
                    }
                }
            }
        }
    }
`;
export default SuperOps;
